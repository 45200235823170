export const array = [
  {
    label: "Anxiety",
    count: 161,
  },
  {
    label: "Social Relationships",
    count: 109,
  },
  {
    label: "Behavioral Change",
    count: 91,
  },
  {
    label: "stress",
    count: 81,
  },
  {
    label: "Cough",
    count: 181,
  },
  {
    label: "Brain Cancer",
    count: 75,
  },
  {
    label: "fever",
    count: 231,
  },
  {
    label: "hair fall",
    count: 81,
  },
  {
    label: "Common cold",
    count: 97,
  },
  {
    label: "Covid 19",
    count: 76,
  },
  {
    label: "Cold",
    count: 46,
  },
  {
    label: "Headache",
    count: 102,
  },
  {
    label: "stress",
    count: 81,
  },
  {
    label: "stress",
    count: 81,
  },
  {
    label: "stress",
    count: 81,
  },

];
